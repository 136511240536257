import {
  CustomTheme,
  makeStyles,
  MuiThemeProvider,
  useTheme,
} from '@material-ui/core/styles';
import * as React from 'react';
import {
  BottomNavigationAction,
  Button,
  Divider,
  Icon,
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import classNames from 'class-names';
import { BrandsEnum } from '../../surveyCollector/SurveyCollector';
import { useWindowSize } from '../utils/screen';

let isSkoda = false;
const getSmallDeviceStyles = (theme: CustomTheme) => {
  switch (theme.brandCode) {
    case BrandsEnum.SKODA: {
      return {
        bottom: 0,
        right: 0,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column-reverse',
        gap: '0.5rem',
      };
    }
    default: {
      return {
        padding: `0 ${theme.spacing(3)}px`,
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: '100%',
      };
    }
  }
};

const useStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      padding: theme.brandCode === BrandsEnum.SKODA ? `0 0` : '24px 24px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: theme.brandCode === BrandsEnum.SKODA ? `0.25rem 0` : '0',
      position: theme.brandCode === BrandsEnum.SKODA ? 'fixed' : 'absolute',
      ...getSmallDeviceStyles(theme),
    },
    [theme.breakpoints.between('sm', 'md')]: {
      padding: theme.brandCode === BrandsEnum.SKODA ? `1rem 0` : '0',
      position: theme.brandCode === BrandsEnum.SKODA ? 'fixed' : 'absolute',
      bottom: 0,
      left: 0,
      width: '100vw',
      display: 'flex',
      alignContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    backgroundColor: '#ffffff',
    zIndex: 10,
    paddingRight: '8px',
  },
  squareButton: {
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    boxShadow: 'none',
    textTransform: 'none',
    height: theme.brandCode === BrandsEnum.SKODA ? '46px' : 'auto',
    padding:
      theme.brandCode === BrandsEnum.SKODA ? '14px 24px 14px 24px' : 'inherit',
    [theme.breakpoints.down('sm')]: {
      width: theme.brandCode === BrandsEnum.SKODA ? '97%' : 'auto',
      float: theme.brandCode === BrandsEnum.SKODA ? 'none' : 'right',
    },
    [theme.breakpoints.up('sm')]: {
      width: theme.brandCode === BrandsEnum.SKODA ? '201px' : 'auto',
      float: 'right',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      float: 'none',
    },
  },
  backButton: {
    backgroundColor: '#ffffff',
    height: theme.brandCode === BrandsEnum.SKODA ? '46px' : 'auto',
  },
  divider: {
    float: 'left',
    width: '100%',
    height: theme.brandCode === BrandsEnum.SKODA ? 0 : 4,
    backgroundColor: theme.palette.primary.main,
    display: theme.brandCode === BrandsEnum.SKODA ? 'none' : 'unset',
  },
  icon: {
    transform: `scaleX(${theme.direction === 'rtl' ? -1 : 1})`,
  },
  brandCode: {
    code: theme.brandCode,
  },
  navigationWrapper: {
    [theme.breakpoints.between('sm', 'md')]: {
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row-reverse',
      gap: '0.5em',
      paddingRight: '8px',
      maxWidth: '960px',
      width: '100vw',
      minWidth: '600px',
    },
  },
}));

interface NavigationProps {
  onNextClick: () => void;
  onPreviousClick: () => void;
  goBackDisabled: boolean;
}

const SurveyNavigation: React.FunctionComponent<NavigationProps> = ({
  onNextClick,
  onPreviousClick,
  goBackDisabled = false,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const window = useWindowSize();
if (theme.brandCode === BrandsEnum.SKODA) {isSkoda = true;}
  const NavigationButtons = (
    <React.Fragment>
      <Button
        data-test-id="goForwardBtn"
        onClick={onNextClick}
        variant="contained"
        color="primary"
        className={classes.squareButton}>
        {theme.brandCode === BrandsEnum.SKODA ? (
          ''
        ) : (
          <Icon className={classes.icon}>chevron_right</Icon>
        )}
        <Trans i18nKey="ui.surveyNavigation.goForward">
          {theme.brandCode === BrandsEnum.SKODA
            ? 'Next question'
            : 'Go forward'}
        </Trans>
      </Button>
      <Button
        disabled={goBackDisabled}
        data-test-id="goBackBtn"
        onClick={onPreviousClick}
        className={classNames(classes.squareButton, classes.backButton)}>
        <Trans i18nKey="ui.surveyNavigation.goBack">Go back</Trans>
        {theme.brandCode === BrandsEnum.SKODA ? (
          ''
        ) : (
          <Icon className={classes.icon}>chevron_left</Icon>
        )}
      </Button>
    </React.Fragment>
  );

  return (
    <MuiThemeProvider
      theme={{
        ...theme,
      }}>
      <div className={classes.root}>
        {window.width >= 600 && window.width < 1280 ? (
          <div className={classes.navigationWrapper}>{NavigationButtons}</div>
        ) : (
          NavigationButtons
        )}
        <Divider className={classes.divider} />
      </div>
    </MuiThemeProvider>
  );
};

export default SurveyNavigation;
