import * as React from 'react';
import { Button, CustomTheme, Grid, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { append, remove } from 'ramda';
import nl2br from 'nl2br';
import {
  ChoiceButtonOnClickProps,
  ChoiceValue,
  MultiChoiceProps,
  Choice,
} from './ElementTypeInterfaces';
import classNames from 'class-names';
import { BrandsEnum } from '../surveyCollector/SurveyCollector';
import TagManager from 'react-gtm-module';

let isSkoda = false;

const useStyles = makeStyles((theme: CustomTheme) => ({
  label: {
    flexGrow: 2,
    flexBasis: 0,
    fontSize: '0.85rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block',
  },
  squareButton: {
    flexGrow: 2,
    flexBasis: 0,
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    margin:
      theme.brandCode === BrandsEnum.SKODA
        ? `${theme.spacing() / 2}px 0`
        : `${theme.spacing()}px 0`,
    textTransform: 'none',
    paddingLeft: 0,
    height: '40px',
    ...(theme.brandCode === BrandsEnum.SKODA && {
      // spread operator
      border: '2px solid',
    }),
    [theme.breakpoints.down('md')]: {
      lineHeight: theme.brandCode === BrandsEnum.SKODA ? 1.1 : 1.75,
    },
  },
  checkButton: {
    boxShadow: 'none',
    margin: `-${theme.spacing()}px 0`,
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    flexGrow: 1,
    maxWidth: 40,
    borderRight: '1px solid rgba(0, 0, 0, 0.23)',
  },
  checkButtonSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    height: '40px',
    borderRight: 'none',
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
  },
  indexButton: {
    boxShadow: 'none',
    borderRadius: 0,
    flexGrow: 1,
    maxWidth: 40,
    margin: `${theme.spacing()}px`,
    marginRight: 0,
  },
  indexButtonSelected: {
    color: '#000000 !important',
    borderColor: '#000000',
  },
  root: {
    maxWidth: 800,
    width: '100%',
  },
}));

interface MultiChoiceButtonProps {
  choice: Choice;
  onChange: (value) => void;
  selectedIndex: number;
  showOrder: boolean;
  values?: number[];
}

const isDisable = (choice: Choice, values: number[] = []): boolean => {
  if (choice.value === 999) {
    return values.length > 0 && values[0] !== 999;
  }
  return values.length > 0 && values[0] === 999;
};

const ChoiceButton: React.FunctionComponent<
  ChoiceButtonOnClickProps & MultiChoiceButtonProps
> = ({ choice, values, onChange, showOrder, selectedIndex }) => {
  const classes = useStyles();

  const selected = selectedIndex !== -1;

  const dataTestButtonTitle = `multiChoiceButton${choice.value}`;

  const onClick = () => {
    onChange(choice.value);

    if (isSkoda) {
      const tagManagerArgs = {
        dataLayer: {
          matrix_id: '5',
          event: 'trackEvent',
          event_name: 'button_click',
          page_group: 'SCX',
          button_text: choice.value
        },
        dataLayerName: 'PageDataLayer'
      }
      TagManager.dataLayer(tagManagerArgs);
    }
  };

  return (
    <Grid
      container
      direction="row"
      key={choice.value}
      justify="space-evenly"
      alignItems="stretch">
      <Button
        data-test-id={dataTestButtonTitle}
        disabled={isDisable(choice, values)}
        onClick={onClick}
        className={classes.squareButton}
        variant="outlined"
        color={selected ? 'primary' : 'default'}>
        <Grid
          container
          direction="row"
          key={choice.value}
          justify="space-evenly"
          alignItems="center">
          <Button
            component={'div'}
            style={{ backgroundColor: 'transparent' }}
            className={classNames(classes.checkButton, {
              [classes.checkButtonSelected]: selected,
            })}>
            {selected ? <CheckIcon style={{ color: '#000' }} /> : ''}
          </Button>
          <span
            className={classes.label}
            dangerouslySetInnerHTML={{ __html: nl2br(choice.text) }}
          />
        </Grid>
      </Button>
      {showOrder ? (
        <Button
          variant="outlined"
          disabled
          className={classNames(classes.indexButton, {
            [classes.indexButtonSelected]: selected,
          })}>
          {!selected ? '#' : selectedIndex + 1}
        </Button>
      ) : (
        ''
      )}
    </Grid>
  );
};

const MultiChoiceInner: React.FunctionComponent<MultiChoiceProps> = ({
  choices,
  showOrder = false,
  value = [] as number[],
  onChange,
}) => {
  const classes = useStyles();

  const onChangeHandler = (val: ChoiceValue) => {
    const index = value.indexOf(val);
    if (index !== -1) {
      onChange(remove(index, 1, value));
    } else {
      onChange(append<ChoiceValue>(val, value));
    }
  };

  return (
    <div className={classes.root}>
      {choices.map((choice) => (
        <ChoiceButton
          key={choice.value}
          selectedIndex={value.indexOf(choice.value)}
          values={value}
          choice={choice}
          onChange={onChangeHandler}
          showOrder={showOrder}
        />
      ))}
    </div>
  );
};

export default MultiChoiceInner;
