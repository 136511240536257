import * as React from 'react';
import { PersonalAgreementQuestionProps as Props } from './ElementTypeInterfaces';
import nl2br from 'nl2br';
import {
  FormControlLabel,
  Checkbox,
  Fab,
  Typography,
  Paper,
  Hidden,
  makeStyles,
  MuiThemeProvider,
  useTheme,
  CustomTheme,
} from '@material-ui/core';

import personalAgreementBg from '../static/images/personal-agreement-bg.png';
import personalAgreementBgTriangle from '../static/images/personal-agreement-bg-triangle.png';
import { BrandsEnum } from '../surveyCollector/SurveyCollector';
import { useWindowSize } from '../uiState/utils/screen';
import TagManager from 'react-gtm-module';

const preloadImage = (url: string) => {
  const img = new Image();
  img.src = url;
};
setTimeout(function () {
  preloadImage(personalAgreementBg);
  preloadImage(personalAgreementBgTriangle);
}, 500);

const useStyles = makeStyles((theme: CustomTheme) => ({
  background: {
    position: theme.brandCode === BrandsEnum.SKODA ? 'fixed' : 'absolute',
    width: '100%',
    height: '100%',
    // @ts-ignore
    backgroundImage: `url(${theme.personalAgreement.backgroundImagePath})`,
    backgroundPosition: theme.brandCode === BrandsEnum.SKODA ? 'top' : 'center',
    backgroundSize: 'cover',
    right: theme.brandCode === BrandsEnum.SKODA ? 'auto' : 0,
    left: theme.brandCode === BrandsEnum.SKODA ? 0 : 'auto',
    zIndex: -1,
    top: theme.brandCode === BrandsEnum.SKODA ? '65px' : 0,
    [theme.breakpoints.down('lg')]: {
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA
          ? '#78faae'
          : `url(${theme.personalAgreement.backgroundImagePath})`,
      backgroundImage: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: `url(${theme.personalAgreement.backgroundImagePath})`,
    },
  },
  backgroundTriangle: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    width: 300,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  paper: {
    maxWidth: theme.brandCode === BrandsEnum.SKODA ? '608px' : '650px',
    padding: theme.spacing(4),
    boxShadow: '13px 10px 10px 0px rgba(0,0,0,0.2)',
  },
  terminateButton: {
    backgroundColor: '#ffffff',
    border:
      theme.brandCode === BrandsEnum.SKODA
        ? 'solid #C4C6C7 2px'
        : 'solid #E1E1E1 2px',
    boxShadow: 'none',
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    textTransform: theme.brandCode === BrandsEnum.SKODA ? 'none' : 'auto',
    color: theme.brandCode === BrandsEnum.SKODA ? '#C4C6C7' : 'auto',
    '&:hover': {
      backgroundColor: '#E1E1E1',
    },
    [theme.breakpoints.up('md')]: {
      marginRight: theme.brandCode === BrandsEnum.SKODA ? 0 : theme.spacing(2),
      minWidth: theme.brandCode === BrandsEnum.SKODA ? '512px' : '200px',
    },
    [theme.breakpoints.only('md')]: {
      minWidth:
        theme.brandCode === BrandsEnum.SKODA
          ? '-webkit-fill-available'
          : '200px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: theme.brandCode === BrandsEnum.SKODA ? '99%' : '200px',
      marginRight: theme.brandCode === BrandsEnum.SKODA ? 0 : theme.spacing(2),
    },
  },
  continueButton: {
    minWidth: 200,
    boxShadow: 'none',
    borderRadius: theme.brandCode === BrandsEnum.SKODA ? '50px' : 0,
    textTransform: theme.brandCode === BrandsEnum.SKODA ? 'none' : 'auto',
    marginBottom:
      theme.brandCode === BrandsEnum.SKODA ? theme.spacing(1) : 'auto',
    '&:hover': {
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA ? '#A8ffcc' : 'inherit',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: theme.spacing(),
      width: theme.brandCode === BrandsEnum.SKODA ? '99%' : '200px',
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA
          ? '#464748'
          : theme.personalAgreement.continueButtonColor,
      color: '#fff',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: theme.brandCode === BrandsEnum.SKODA ? '512px' : '200px',
      backgroundColor: theme.personalAgreement.continueButtonColor,
      color: theme.brandCode === BrandsEnum.SKODA ? '#000000' : '#fff',
    },
    [theme.breakpoints.only('md')]: {
      minWidth:
        theme.brandCode === BrandsEnum.SKODA
          ? '-webkit-fill-available'
          : '200px',
      backgroundColor:
        theme.brandCode === BrandsEnum.SKODA
          ? '#464748'
          : theme.personalAgreement.continueButtonColor,
      color: '#fff',
    },
  },
  continueText: {
    marginBottom: theme.spacing(2),
    fontSize: '16px',
  },
  agreement: {
    marginBottom: theme.spacing(4),
  },
  agreementLabel: {
    width: '100%',
    textAlign: 'left',
  },
  thanksMessage: {
    color: '#000',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: 200,
    fontSize: '24px',
  },
  buttons: {
    float: 'right',
    width: theme.brandCode === BrandsEnum.SKODA ? '100%' : 'auto',
    [theme.breakpoints.down('sm')]: {
      display: theme.brandCode === BrandsEnum.SKODA ? 'unset' : 'flex',
      flexDirection:
        theme.brandCode === BrandsEnum.SKODA ? 'column' : 'column-reverse',
      width: '100%',
    },
  },
  label: {
    fontSize: '14px',
  },
}));

const PersonalAgreementQuestion: React.FunctionComponent<Props> = ({
  agreement,
  continueButtonText,
  continueText,
  onChange,
  terminateButtonText,
  thanksMessage,
  ...otherProps
}) => {
  const [agreementChecked, setAgreementChecked] = React.useState<boolean>(
    false
  );
  const [showError, setShowError] = React.useState<boolean>(false);

  const agreementCheckHandler = () => {
    setAgreementChecked((prev) => !prev);
  };
  const isShowErrorHandler = () => {
    setShowError((prev) => !prev);
  };
  const onContinueClick = () => {
    const tagManagerArgs = {
      dataLayer: {
        matrix_id: '5',
        event: 'trackEvent',
        event_name: 'button_click',
        page_group: 'SCX',
        button_text: 'Next question is clicked'
      },
      dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);
    agreementChecked ? onChange(1) : isShowErrorHandler();
  };

  const onTerminateClick = () => {
    const tagManagerArgs = {
      dataLayer: {
        matrix_id: '5',
        event: 'trackEvent',
        event_name: 'button_click',
        page_group: 'SCX',
        button_text: 'Button End is clicked'
      },
      dataLayerName: 'PageDataLayer'
    }
    TagManager.dataLayer(tagManagerArgs);
    onChange(0);
  }
  const theme = useTheme();
  const classes = useStyles();
  const windowSize = useWindowSize();
  return (
    <MuiThemeProvider
      theme={{
        ...theme,
      }}>
      <div className={classes.background} />
      {theme.brandCode === BrandsEnum.SKODA ? (
        ''
      ) : (
        <Hidden smDown>
          <img
            src={personalAgreementBgTriangle}
            alt="triangle"
            className={classes.backgroundTriangle}
          />
        </Hidden>
      )}
      <Typography
        className={classes.thanksMessage}
        variant="h5"
        align="left"
        dangerouslySetInnerHTML={{ __html: nl2br(thanksMessage) }}
      />
      {theme.brandCode === BrandsEnum.SKODA ? (
        <div>
          <Typography
            variant="body1"
            className={classes.continueText}
            align="left"
            dangerouslySetInnerHTML={{ __html: nl2br(continueText) }}
          />
          {agreement && (
            <div className={classes.agreement}>
              <div className={classes.agreementLabel}>
                <FormControlLabel
                  className={classes.agreementLabel}
                  classes={{
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                      data-test-id="checkBox"
                      onClick={agreementCheckHandler}
                      value="agreement"
                      checked={agreementChecked}
                    />
                  }
                  label={agreement.text}
                />
              </div>
              {showError && (
                <Typography
                  style={{ fontSize: '0.875rem' }}
                  align="center"
                  color="error">
                  {agreement.errorMessage}
                </Typography>
              )}
            </div>
          )}
          <div className={classes.buttons}>
            <Fab
              data-test-id="continueButton"
              variant="extended"
              onClick={onContinueClick}
              size="large"
              color="primary"
              className={classes.continueButton}>
              <Typography variant="caption" color="inherit">
                {continueButtonText}
              </Typography>
            </Fab>
            <Fab
              data-test-id="terminateButton"
              variant="extended"
              onClick={onTerminateClick}
              size="large"
              color="secondary"
              className={classes.terminateButton}>
              <Typography variant="caption" color="inherit">
                {terminateButtonText}
              </Typography>
            </Fab>
          </div>
        </div>
      ) : (
        <Paper square className={classes.paper}>
          <Typography
            variant="body1"
            className={classes.continueText}
            align="left"
            dangerouslySetInnerHTML={{ __html: nl2br(continueText) }}
          />
          {agreement && (
            <div className={classes.agreement}>
              <div className={classes.agreementLabel}>
                <FormControlLabel
                  className={classes.agreementLabel}
                  classes={{
                    label: classes.label,
                  }}
                  control={
                    <Checkbox
                      data-test-id="checkBox"
                      onClick={agreementCheckHandler}
                      value="agreement"
                      checked={agreementChecked}
                    />
                  }
                  label={agreement.text}
                />
              </div>
              {showError && (
                <Typography
                  style={{ fontSize: '0.875rem' }}
                  align="center"
                  color="error">
                  {agreement.errorMessage}
                </Typography>
              )}
            </div>
          )}
          <div className={classes.buttons}>
            <Fab
              data-test-id="terminateButton"
              variant="extended"
              onClick={onTerminateClick}
              size="large"
              color="default"
              className={classes.terminateButton}>
              <Typography variant="caption" color="inherit">
                {terminateButtonText}
              </Typography>
            </Fab>
            <Fab
              data-test-id="continueButton"
              variant="extended"
              onClick={onContinueClick}
              size="large"
              color="secondary"
              className={classes.continueButton}>
              <Typography variant="caption" color="inherit">
                {continueButtonText}
              </Typography>
            </Fab>
          </div>
        </Paper>
      )}
    </MuiThemeProvider>
  );
};

export default PersonalAgreementQuestion;
